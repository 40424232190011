import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  badgeTitle: string
  bottomMargin?: number
  buttonName: string
  buttonUrl?: string
  colorPalette: ColorPalette
  colSizeOne: number
  colSizeTwo: number
  fontTextColor: string
  image: string
  imageTitle: string
  subtitle: string
  title: string
  titleTopLeft: string
  imageSubtitle?: string
  imageTitleSubtitle?: string
  asoEffect: string
}

/** const */
const PlumbingProductSection: React.FC<Props> = (props) => {
  return (
    <section
      className={`container-fluid text-left p-5 pt-5 pb-5`}
      style={{
        background: "#EFEFEF",
        color: "#365c70",
      }}
    >
      <div className="container">
        <div
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
          style={{ fontSize: "24px", color: "#365c70" }}
        >
          Plumbing
        </div>

        <hr
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
            color: "",
          }}
        />


       <div class="container m-0 p-0 mt-3 mb-5 d-none d-sm-block">
          <div class="row">
            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/plumbing/1.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/plumbing/2.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/plumbing/3.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>

       <div class="container m-0 p-0 d-block d-sm-none">
          <img
            data-aos="Fade"
            src="/plumbing/1.jpg"
            loading="lazy"
            decoding="async"
            alt=""
            width="100%"
          />
        </div>


        <div className="contentHeroSpace hidden-xs"></div>

        <div className="row">
         <div
           className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
           style={{ fontSize: "18px", color: "#365c70" }}
         >
         </div>

         <div
            className="hidden-sm hidden-md hidden-lg hidden-xl mb-5 p-0"
            style={{ fontSize: "18px", color: "" }}
          >
            Norhart Supply provides an array of plumbing products, ranging from pipes, fittings and water heaters to tubs, toilets and faucets.  Our wide variety of faucets can give any kitchen, bathroom or laundry room a fantastic finishing touch.

            <p/>

            <strong>Pipes & fittings</strong>
            <br/>
            We provide several sizes, lengths and types of pipes and fittings to complete plumbing projects.  Norhart Supply carries materials such as PVC, galvanized steel and brass pipes to complete plumbing indoors, whether it's hooking up your kitchen faucet, bathroom sinks and tubs, or washing machines, and outdoor plumbing like drainage and sewer lines.  Every sink, faucet, toilet, tub and water heater rely on the system behind it—the pipes and fittings.

            <p/>
            <hr/>

            <strong>Faucets</strong>
            <br/>
            Norhart Supply carries numerous sleek and modern faucets, adding an extra wow factor to any bathroom or kitchen.  We have different sized faucets to complement and fit all different types of sinks.  We have kitchen faucets with stronger streams of water, and different hoses and heads to spray off dishes and keep your sink clean.
            <p/>
            <hr/>

            <strong>Toilets</strong>
            <br/>
            Norhart Supply provides toilets for business and home use.  We have eco-friendly toilets that reduce water usage.  The toilets have a strong flush as well, while still maintaining their eco-friendly characteristics.
            <p/>
            <hr/>

            <strong>Tubs</strong>
            <br/>
            Bathtubs carried by Norhart Supply come in a variety.  We provide tubs that best optimize the space of your bathroom.  From corner tubs to standard tubs of various lengths, Norhart Supply has the tubs you need.  For the multifamily industry, we also carry tubs that meet ADA standards.
            <p/>
            <hr/>

            <strong>Water heaters</strong>
            <br/>
            Water heaters are an important component to all buildings.  Supplied in varying sizes and capacities, Norhart Supply has various water heaters in order to meet your needs.  Larger sized water heaters are ideal for residences with more water usage.  In the multifamily industry, when units have individual water heaters, large ones are not necessary.
            <p/>

          </div>
        </div>
      </div>
    </section>
  )
}

export default PlumbingProductSection
