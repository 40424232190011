import { isTodayInBetween } from "../utils/date"

interface HeroImage {
  titleOne: string
  subTitleOne: string
  imageOne: string
  twitterCardOne: string
  twitterOpenGraphOne: string
}

const useHeroImage = () => {
  const getHeroImage = (): HeroImage => {
    if (isTodayInBetween(new Date("12/1/2022"), new Date("12/31/2022"), true)) {
      return heroImageOptions.winterHoliday
    }
    if (isTodayInBetween(new Date("05/04/2022"), new Date("05/04/2022"), true)) {
      return heroImageOptions.maythefourth
    }
    if (isTodayInBetween(new Date("06/20/2022"), new Date("08/30/2022"), true)) {
      return heroImageOptions.bestplacetowork
    }
    return heroImageOptions.standard
  }

  return getHeroImage()
}

export default useHeroImage

/** mock data */
interface HeroImageOptions {
  /** standard hero image */
  standard: HeroImage

  /** winter holiday hero image */
  winterHoliday: HeroImage

  /** may the fourth hero image */
  maythefourth: HeroImage

  /** best places to work hero image */
  bestplacetowork: HeroImage
}

const heroImageOptions: HeroImageOptions = {
  standard: {
    titleOne: "Norhart Supply",
    subTitleOne: "Top Workplaces Award Winner",
    imageOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero.png",
    twitterCardOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-twitter-card.png",
    twitterOpenGraphOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-open-graph.png",
  },
  winterHoliday: {
    titleOne: "Norhart Supply",
    subTitleOne: "Top Workplaces Award Winner",
    imageOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero.png",
    twitterCardOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-twitter-card.png",
    twitterOpenGraphOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-open-graph.png",
  },
  maythefourth: {
    titleOne: "Norhart Supply",
    subTitleOne: "Top Workplaces Award Winner",
    imageOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero.png",
    twitterCardOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-twitter-card.png",
    twitterOpenGraphOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-open-graph.png",
  },
  bestplacetowork: {
    titleOne: "Norhart Supply",
    subTitleOne: "Top Workplaces Award Winner",
    imageOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero.png",
    twitterCardOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-twitter-card.png",
    twitterOpenGraphOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-open-graph.png",
  },
}
